import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import twitterIcon from '../images/twitter.svg'
import instaIcon from '../images/instagram.svg'
import discordIcon from '../images/discord.svg'

const SocialBar = () => {
  const {
    allContentfulSiteMetaData: {
      edges: [
        {
          node: { twitterUrl, instagramUrl, discordUrl },
        },
      ],
    },
  } = useStaticQuery(
    graphql`
      query {
        allContentfulSiteMetaData {
          edges {
            node {
              twitterUrl
              instagramUrl
              discordUrl
            }
          }
        }
      }
    `
  )

  return (
    <div className="flex flex-col justify-between mb-6">
      <div className="flex items-center">
        <img
          src={twitterIcon}
          alt="Read more"
          className="inline mr-1.5"
          style={{ height: '1rem' }}
        />
        <a
          href={twitterUrl}
          target="_blank"
          rel="noreferrer"
          className="hover:text-gray"
        >
          Twitter
        </a>
      </div>
      <div className="flex items-center">
        <img
          src={instaIcon}
          alt="Read more"
          className="inline mr-1.5"
          style={{ height: '1rem' }}
        />
        <a
          href={instagramUrl}
          target="_blank"
          rel="noreferrer"
          className="hover:text-gray"
        >
          Instagram
        </a>
      </div>{' '}
      <div className="flex items-center">
        <img
          src={discordIcon}
          alt="Read more"
          className="inline mr-1.5"
          style={{ height: '1rem' }}
        />

        <a
          href={discordUrl}
          target="_blank"
          rel="noreferrer"
          className="hover:text-gray"
        >
          Discord
        </a>
      </div>
    </div>
  )
}

export default SocialBar
