import * as React from 'react'
import { Link } from 'gatsby'
import Separator from './Separator'

const Header = () => {
  return (
    <div>
      <nav className="nav flex flex-col flex-wrap justify-between py-6">
        <Link to="/" className="no-underline font-display text-7xl uppercase">
          Studio TBD
        </Link>
      </nav>
      <Separator />
    </div>
  )
}

Header.propTypes = {}

export default Header
