import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './Header'
import SocialBar from '../components/SocialBar'
import '../styles/global.css'

const Layout = ({ children }) => {
  const {
    site,
    allContentfulSiteMetaData: {
      edges: [
        {
          node: { emailContact },
        },
      ],
    },
  } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allContentfulSiteMetaData {
        edges {
          node {
            emailContact
          }
        }
      }
    }
  `)

  return (
    <div className="px-6 max-w-4xl mx-auto min-w-320">
      <Header siteTitle={site.siteMetadata?.title || `Title`} />
      <div>
        <main>{children}</main>
        <footer className="flex justify-between py-6">
          <SocialBar />
          <a href={`mailto:${emailContact}`}>{emailContact}</a>
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
