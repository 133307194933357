import classNames from 'classnames'
import * as React from 'react'

const Separator = ({ className }) => {
  return (
    <hr
      className={classNames('clear-both', className)}
      style={{ transform: 'scaleY(.65)' }}
    />
  )
}

export default Separator
